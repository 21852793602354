<template>
  <div class="flex flex-col">
    <LoadingSpinner :isLoading="isLoading" />
    <div class="border-b border-secondary-300 mb-2">
      <ValidationObserver ref="formAlarmSearch">
        <div class="flex flex-wrap">
          <div class="w-full lg:w-4/12 lg:pr-4 py-2">
            <ValidationProvider name="IMEI/Telematiknummer/Serienummer" rules="required" v-slot="{ classes, errors }">
              <div class="input-validate" :class="classes">
                <input type="text" v-model.trim="searchQuery" placeholder="IMEI/Telematiknummer/Serienummer" v-on:keyup.enter="searchAlarm" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2" />
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="w-full lg:w-4/12 py-2">
            <button class="btn-blue text-sm px-5 py-2" @click="searchAlarm()">
              Sök
              <BaseIcon icon="search" class="ml-1" />
            </button>
          </div>
        </div>
      </ValidationObserver>
    </div>
    <div class="flex flex-wrap text-sm pb-2 mb-2" v-if="alarm != null">
      <div v-if="customer && (user.role == 'admin' || user.role == 'seller' || user.role == 'reseller')" class="w-full lg:w-1/4 px-2 py-2"><b>Kund:</b> {{ customer.name }}</div>
      <div v-if="unit && (user.role == 'admin' || user.role == 'seller' || user.role == 'reseller')" class="w-full lg:w-1/4 px-2 py-2"><b>Verksamhet:</b> {{ unit.name }}</div>
      <div v-if="user.role == 'admin' || user.role == 'seller' || user.role == 'reseller'" class="w-full lg:w-1/4 px-2 py-2"><b>Namn:</b> {{ alarm.unit_name }}</div>
      <div class="w-full lg:w-1/4 px-2 py-2"><b>Telematiknummer:</b> {{ alarm.phone }}</div>
      <div class="w-full lg:w-1/4 px-2 py-2"><b>Serienummer:</b> {{ alarm.modelnumber }}</div>
      <div class="w-full lg:w-1/4 px-2 py-2"><b>IMEI:</b> {{ alarm.imei_number }}</div>
      <div class="w-full lg:w-1/4 px-2 py-2"><b>Enhetstyp:</b> {{ alarm.type }}</div>
      <div class="w-full flex flex-wrap mt-2" v-if="user.role == 'admin' || user.role == 'seller'">
        <div class="w-full lg:w-1/6 px-2">
          <router-link v-if="alarm" target="_blank" :to="'/alarm-report/alarm/' + alarm.imei_number" class="link">Larmrapport</router-link>
        </div>
        <div class="w-full lg:w-1/6 px-2">
          <router-link v-if="alarm && customer && unit" target="_blank" :to="'/larm/customer/' + customer.customer_id + '/' + unit.unit_id + '/-/' + alarm._id" class="link">Larmplan</router-link>
        </div>
        <div class="w-full lg:w-1/6 px-2">
          <router-link v-if="alarm && unit" target="_blank" :to="'/gps-unit/customer/' + unit.unit_id + '/-/' + alarm._id" class="link">SecurTrack</router-link>
        </div>
        <div class="w-full lg:w-1/6 px-2">
          <router-link v-if="alarm && unit" target="_blank" :to="'/ronderingsbevakning/unit/' + unit.unit_id" class="link">SecurTool</router-link>
        </div>
        <div class="w-full lg:w-1/6 px-2">
          <router-link v-if="alarm && unit" target="_blank" :to="'/alarm-reminder/unit/' + unit.unit_id" class="link">Provlarm</router-link>
        </div>
      </div>
    </div>
    <div v-if="alarm == null && querySearched != ''" class="text-sm text-center border-b border-secondary-300 pb-2 mb-2">No result found for query - {{ querySearched }}</div>
    <div v-show="showConfig && alarm && new RegExp('^SRT').test(alarm.type)" class="flex flex-col">
      <div class="flex flex-col mb-2">
        <div class="flex flex-wrap">
          <div class="w-full lg:w-4/12 lg:pr-4 py-2">
            <input type="file" accept="text/plain" ref="commandTextFile" @change="textFileRead()" :disabled="isWaiting" :readonly="isWaiting" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none transition duration-500 px-3 py-2 disabled:opacity-50" />
          </div>
          <div class="w-full lg:w-8/12">
            <div class="clear-both">
              <div class="float-left">
                <button class="btn-secondary-outline text-sm px-4 py-2 mr-3 my-2" @click="testConnection()" :disabled="isWaiting">
                  Check
                  <BaseIcon icon="exchange-alt" class="ml-1" />
                </button>
                <button class="btn-green-outline text-sm px-4 py-2 mr-3 my-2" v-bind:class="{ 'bg-accent-500 text-white': readMode }" @click="read()" :disabled="isWaiting">
                  Read
                  <BaseIcon icon="book-open" class="ml-1" />
                </button>
                <button class="btn-secondary-outline text-sm py-2 mr-3 my-2" @click="downloadConfig()" :disabled="isWaiting || commandQueue.length == 0">
                  <BaseIcon icon="download" />
                </button>
                <button class="btn-red-outline text-sm px-4 py-2 mr-3 my-2" @click="eraseAlarmConfigPrompt()" :disabled="isWaiting">
                  Default
                  <BaseIcon icon="eraser" class="ml-1" />
                </button>
                <button class="btn-secondary-outline text-sm px-4 py-2 my-2" @click="reconnectPrompt()" :disabled="isWaiting">
                  Reconnect
                  <BaseIcon icon="sync" class="ml-1" />
                </button>
              </div>
              <div class="float-right">
                <button class="btn-secondary-outline text-sm px-4 py-2 mr-3 my-2" @click="reset()" :disabled="isWaiting">
                  Reset
                  <BaseIcon icon="undo" class="ml-1" />
                </button>
                <button class="btn-blue text-sm px-4 py-2 my-2" @click="send()" :disabled="isWaiting">
                  Skicka
                  <BaseIcon icon="angle-double-right" class="ml-1" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="text-file-box bg-gray-50">
          <div v-if="commandQueue.length == 0" class="w-full">
            <input type="text" v-model.trim="command" placeholder="Command" v-on:keyup.enter="sendCommand()" :disabled="isWaiting" :readonly="isWaiting" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2 disabled:opacity-50" v-bind:class="{ 'bg-red-100 border-red-200': checkDangerCommand(command) }" />
          </div>
          <div class="flex group" v-for="(c, i) in commandQueue" :key="c.id">
            <input type="text" v-model="c.command" :disabled="isWaiting" :readonly="isWaiting" class="bg-gray-100 w-full text-sm text-gray-900 focus:outline-none border border-t-0 border-gray-300 px-3 py-1 disabled:opacity-50 group-hover:bg-gray-200" v-bind:class="{ 'bg-red-100 group-hover:bg-red-200': checkDangerCommand(c.command), 'border-t': i == 0 }" />
            <button class="btn-red rounded-none border-t-0" v-bind:class="{ 'border-t': i == 0 }" :disabled="isWaiting" @click.prevent="removeCommand(i)">
              <BaseIcon icon="minus-circle" class="" />
            </button>
          </div>
        </div>
      </div>
      <div ref="configLog" class="flex flex-col config-log-box bg-secondary-800 border border-secondary-300 rounded px-3 py-3">
        <div class="text-white text-sm mt-1 whitespace-pre-line break-words" v-for="(l, i) in logs" :key="i">
          <span class="mr-2 text-white">{{ l.timestamp }}</span>
          <BaseIcon icon="info-circle" class="mr-2" v-bind:class="{ 'text-accent-500': l.type == 'success', 'text-red-500': l.type == 'error', 'text-yellow-400': l.type == 'warning' }" />
          <span v-bind:class="{ 'text-accent-500': l.type == 'success', 'text-red-500': l.type == 'error', 'text-yellow-400': l.type == 'warning' }">{{ l.message }}</span>
        </div>
        <div v-if="isWaiting" class="text-white text-lg mt-1">
          <BaseIcon icon="spinner" spin />
        </div>
      </div>
    </div>
    <div v-show="showConfig && alarm && new RegExp('^TWIG').test(alarm.type)" class="flex flex-col">
      <div class="flex flex-col mb-2">
        <div class="flex flex-wrap">
          <div class="w-full flex flex-wrap">
            <button class="btn-secondary-outline text-xxs px-2 py-1 mr-2 my-1" @click="testConnection()" :disabled="isWaiting">
              Check
              <BaseIcon icon="exchange-alt" class="ml-1" />
            </button>
            <button class="btn-secondary-outline text-xxs px-2 py-1 mr-2 my-1" @click="command = '?LOC_1'" :disabled="isWaiting">
              Latest Position
              <BaseIcon icon="map-pin" class="ml-1" />
            </button>
            <button class="btn-green-outline text-xxs px-2 py-1 mr-2 my-1" v-bind:class="{ 'bg-accent-500 text-white': readMode }" @click="read()" :disabled="isWaiting">
              Read
              <BaseIcon icon="book-open" class="ml-1" />
            </button>
            <button class="btn-red text-xxs px-2 py-1 mr-2 my-1" @click="eraseAlarmConfigPrompt()" :disabled="isWaiting">
              Default
            </button>
            <button
              class="btn-red-outline text-xxs px-2 py-1 mr-2 my-1"
              @click="
                command = '?CNF_01/01_2510_2';
                sendingMode = 'SMS';
              "
              :disabled="isWaiting"
            >
              Activate Command Channel
            </button>
            <button class="btn-green-outline text-xxs px-2 py-1 mr-2 my-1" @click="command = '?CNF_01/01_2510_0'" :disabled="isWaiting">
              Deactivate Command Channel
            </button>
            <button class="btn-secondary-outline text-xxs px-2 py-1 mr-2 my-1" @click="command = '?TRG_7_1_20'" :disabled="isWaiting || sendingMode == 'SMS'">
              Tracking Process Start
            </button>
            <button class="btn-secondary-outline text-xxs px-2 py-1 mr-2 my-1" @click="getTwigTrackingStopCommand()" :disabled="isWaiting">
              Tracking Process Stop
            </button>
            <button class="btn-secondary-outline text-xxs px-2 py-1 mr-2 my-1" @click="command = '?RST_0'" :disabled="isWaiting">
              Restart Device
            </button>
            <button class="btn-secondary-outline text-xxs px-2 py-1 mr-2 my-1" @click="actTwigBatterySaverCommand()" :disabled="isWaiting">
              Battery Saver
            </button>
            <button class="btn-red-outline text-xxs px-2 py-1 mr-2 my-1" @click="command = '?CNF_01/01_2113_2'" :disabled="isWaiting">
              Disable low battery warning
            </button>
          </div>
          <div class="w-full">
            <div class="clear-both">
              <div class="float-left">
                <div class="py-2">
                  <input type="file" accept=".twig,text/plain" ref="commandTextFileTwig" @change="twigFileRead()"  :disabled="isWaiting" :readonly="isWaiting" 
                  class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none transition duration-500 px-3 py-2 disabled:opacity-50" />
                </div>
              </div>
              <div class="float-right flex flex-wrap">
                <div class="py-2 mr-3">
                  <select v-model="fileDownloadType"
                    class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2 hover:border-gray-400 disabled:opacity-50" 
                    :disabled="isWaiting || commandQueue.length == 0" >
                    <option value="twig">Twig</option>
                    <option value="txt">Txt</option>
                  </select>
                </div>
                <button class="btn-secondary-outline text-sm py-2 mr-3 my-2" @click="downloadTwigConfig()" :disabled="isWaiting || commandQueue.length == 0">
                  <BaseIcon icon="download" />
                </button>
                <button class="btn-secondary-outline text-sm px-4 py-2 mr-3 my-2" @click="reset()" :disabled="isWaiting || commandQueue.length == 0">
                  Reset
                  <BaseIcon icon="undo" class="ml-1" />
                </button>
                <button class="btn-secondary-outline text-sm px-4 py-2 mr-3 my-2" @click="stopQueue()" :disabled="commandQueue.length == 0">
                  Stop queue
                  <BaseIcon icon="hand-paper" class="ml-1" />
                </button>
                <div class="py-2 mr-3">
                  <select v-model="queueWaitingTime" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2 hover:border-gray-400">
                    <option value="0">0</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                    <option value="3000">3000</option>
                    <option value="4000">4000</option>
                    <option value="6000">6000</option>
                  </select>
                </div>
                <div class="py-2 mr-3">
                  <select v-model="sendingMode" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2 hover:border-gray-400">
                    <option value="TCP">TCP</option>
                    <option value="SMS">SMS</option>
                  </select>
                </div>
                <button class="btn-blue text-sm px-4 py-2 my-2" @click="send()" :disabled="isWaiting">
                  Skicka {{ sendingMode }}
                  <BaseIcon icon="angle-double-right" class="ml-1" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="commandQueue.length == 0" class="bg-gray-50">
          <div class="w-full">
            <input type="text" v-model.trim="command" placeholder="Command" v-on:keyup.enter="sendCommand()"
              :disabled="isWaiting" :readonly="isWaiting" 
              class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2 disabled:opacity-50"
               v-bind:class="{ 'bg-red-100 border-red-200': checkDangerCommandTwig(command) }"
            />
          </div>
        </div>
        <div class="text-file-box bg-gray-50">
          <div class="flex group" v-for="(c,i) in commandQueue" :key="c.id">
            <input type="text" v-model="c.command" :disabled="isWaiting" :readonly="isWaiting" 
              class="bg-gray-100 w-full text-sm text-gray-900 focus:outline-none border border-t-0 border-gray-300 px-3 py-1 disabled:opacity-50 group-hover:bg-gray-200" 
              v-bind:class="{ 'bg-red-100 group-hover:bg-red-200': checkDangerCommandTwig(c.command), 'border-t': i == 0 }" />
            <button class="btn-red rounded-none border-t-0" v-bind:class="{ 'border-t': i == 0 }" :disabled="isWaiting" @click.prevent="removeCommand(i)">
              <BaseIcon icon="minus-circle" class="" />
            </button>
          </div>
        </div>
      </div>
      <div ref="configLog" class="flex flex-col config-log-box bg-secondary-800 border border-secondary-300 rounded px-3 py-3">
        <div class="text-white text-sm mt-1 whitespace-pre-line break-words" v-for="(l, i) in logs" :key="i">
          <span class="mr-2 text-white">{{ l.timestamp }}</span>
          <BaseIcon icon="info-circle" class="mr-2" v-bind:class="{ 'text-accent-500': l.type == 'success', 'text-red-500': l.type == 'error', 'text-yellow-400': l.type == 'warning' }" />
          <span v-bind:class="{ 'text-accent-500': l.type == 'success', 'text-red-500': l.type == 'error', 'text-yellow-400': l.type == 'warning' }">{{ l.message }}</span>
        </div>
        <div v-if="isWaiting" class="text-white text-lg mt-1">
          <BaseIcon icon="spinner" spin />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const responseTimeout = 1000 * 70;
const g2Alarms = ["SRT306 G2", "SRT405", "SRT405i", "SRT406", "SRT406i", "SRT341", "SRT430"];
const multipleResponseCommand = ["14", "305", "306", "307", "310", "314", "343", "344"];
const actionCommand = ["5", "7", "8", "85"];

import { io } from "socket.io-client";

export default {
  name: "ConfigureAlarm",
  title() {
    return `Konfigurera larm | SecurCloud`;
  },
  data() {
    return {
      isLoading: false,
      isWaiting: false,
      socket: null,
      showConfig: false,
      readMode: false,
      readModeResponse: [],
      timeoutID: null,
      searchQuery: "",
      querySearched: "",
      alarm: null,
      gps_alarm: null,
      customer: null,
      unit: null,
      command: "",
      commandQueue: [],
      commandQueueTotal: 0,
      commandQueueCurrent: 0,
      logs: [],
      eraseConfigCommandG1: [],
      eraseConfigCommandG2: [],
      eraseConfigCommandTwig: [],
      readCommandG2: [],
      readCommandTwig: [],
      sendingMode: "TCP",
      fileDownloadType: "twig",
      queueWaitingTime: 4000,
    }
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    token() {
      return this.$store.state.token;
    },
  },

  methods: {
    searchAlarm() {
      this.$refs.formAlarmSearch.validate().then(async (success) => {
        if (!success) {
          return;
        }

        try {
          this.isLoading = true;
          let data = { searchQuery: this.searchQuery };

          let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/alarm/config/search`, data);
          this.querySearched = response.data.searchQuery;
          this.alarm = response.data.alarm;
          this.gps_alarm = response.data.gps_alarm;
          this.customer = response.data.customer;
          this.unit = response.data.unit;

          this.logs = [];
          this.reset();

          if (this.gps_alarm) {
            this.lastSeen = this.getAlarmLastSeenTime(this.gps_alarm.updatedAt);
            let logType = "error";

            if (new RegExp("^SRT").test(this.alarm.type) && this.lastSeen.time <= 1000 * 60 * 10) logType = "success";
            else if (new RegExp("^TWIG").test(this.alarm.type) && this.lastSeen.time <= 1000 * 60 * 60) logType = "success";

            this.logs.push({
              timestamp: this.moment().format("HH:mm:ss"),
              type: logType,
              message: `Last report - ${this.lastSeen.value}`,
            });

            this.isWaiting = false;
            if (this.timeoutID != null) {
              clearTimeout(this.timeoutID);
              this.timeoutID = null;
            }

            this.showConfig = true;
          } else this.showConfig = false;

          this.isLoading = false;
          this.$refs.formAlarmSearch.reset();
        } catch (error) {
          this.isLoading = false;
          this.handleError(error);
        }
      });
    },

    initSocketClient() {
      var vm = this;

      this.socket = io(`${process.env.VUE_APP_ALARM_SERVER}/config`, {
        auth: {
          token: this.token,
        },
      });

      this.socket.on("unauthorized", async (data) => {
        try {
          await this.refreshToken();
          this.socket.auth.token = this.token;
          if (this.token) {
            this.socket.connect();
            // retry last failed attempt
            if (data && data.length > 1) this.socket.emit(data[0], data[1]);
          }
        } catch (error) {
          this.handleError(error);
        }
      });

      this.socket.on("connect", () => {
        this.logs.push({
          timestamp: this.moment().format("HH:mm:ss"),
          type: "info",
          message: `Configuration client connected to server.`,
        });
        this.scrollToBottom();
      });

      this.socket.on("disconnect", () => {
        this.logs.push({
          timestamp: this.moment().format("HH:mm:ss"),
          type: "error",
          message: `Configuration client disconnected from server.`,
        });
        this.scrollToBottom();
      });

      this.socket.on("reset_connection_response", (data) => {
        this.logs.push({
          timestamp: this.moment().format("HH:mm:ss"),
          type: data.type,
          message: data.message,
        });
        this.scrollToBottom();
        // recheck connection
        setTimeout(() => {
          vm.checkConnection();
        }, 3000);
      });

      this.socket.on("check_connection_response", (data) => {
        if (data.code == "alarm_connected" && data.imei == this.alarm.imei_number) {
          this.logs.push({
            timestamp: this.moment().format("HH:mm:ss"),
            type: "success",
            message: "Alarm connected to server",
          });
          this.scrollToBottom();
        } else if (data.code == "alarm_disconnected" && data.imei == this.alarm.imei_number) {
          this.logs.push({
            timestamp: this.moment().format("HH:mm:ss"),
            type: "error",
            message: "Alarm disconnected from server",
          });
          // recheck connection
          // setTimeout(() => {
          //   vm.checkConnection();
          // }, 3000);
        }
      });

      this.socket.on("command_response", (data) => {
        this.logs.push({
          timestamp: this.moment().format("HH:mm:ss"),
          type: data.type,
          message: data.message,
        });
        this.scrollToBottom();

        // srt
        if (data.code == "alarm_response" || data.code == "invalid_command" || data.code == "alarm_offline") {
          this.isWaiting = false;
          clearTimeout(this.timeoutID);
          this.timeoutID = null;
          if (data.code != "alarm_offline" && data.typeCommand == "queue_command" && multipleResponseCommand.indexOf(data.parentCommandNumber) == -1) this.sendQueueCommand();
        }

        // handle multiple response command
        if (data.code == "alarm_response" && multipleResponseCommand.indexOf(data.parentCommandNumber) >= 0) {
          this.isWaiting = true;

          let multipleResWaitingTime = 5000;
          if (g2Alarms.indexOf(this.alarm.type) >= 0) multipleResWaitingTime = 2000;

          if (this.readMode == true && data.parentCommandNumber == "14") {
            this.commandQueue.push({
              id: data.commandNumber,
              command: data.message.replace(/^.*#P/, "#C"),
            });
          }

          this.timeoutID = setTimeout(() => {
            vm.isWaiting = false;

            if (vm.readMode && data.parentCommandNumber == "14") {
              vm.command = "";
              vm.readMode = false;
            }

            vm.socket.emit("remove_command", { imei: data.imei, commandNumber: data.parentCommandNumber });
            if (data.typeCommand == "queue_command") this.sendQueueCommand();
          }, multipleResWaitingTime);
        }

        // g2 read
        if (data.code == "alarm_response" && this.readMode == true && g2Alarms.indexOf(this.alarm.type) >= 0) {
          this.readModeResponse.push({
            id: new Date().getTime(),
            command: data.message.replace(/^.*#P/, "#C"),
          });
        }

        // handle no response command
        if (data.code == "alarm_sent" && actionCommand.indexOf(data.commandNumber) >= 0) {
          clearTimeout(this.timeoutID);
          this.timeoutID = null;
          this.timeoutID = setTimeout(() => {
            vm.isWaiting = false;
            vm.socket.emit("remove_command", { imei: data.imei, commandNumber: data.commandNumber });
            if (data.typeCommand == "queue_command") this.sendQueueCommand();
          }, 12000);
        }

        // twig
        if (this.alarm && new RegExp("^TWIG").test(this.alarm.type)) {
          if(this.commandQueue.length != 0) this.isWaiting = true;
          else this.isWaiting = false;

          clearTimeout(this.timeoutID);
          this.timeoutID = null;

          setTimeout(() => {
            this.sendQueueCommand();
          }, this.queueWaitingTime);
        }
      });

      this.socket.on("command_response_broadcast", (data) => {
        if (data.imei == this.alarm.imei_number) 
          this.logs.push({
            timestamp: this.moment().format("HH:mm:ss"),
            type: data.type,
            message: data.message,
          });

        // twig read
        if(data.code == "alarm_response" && this.readMode == true) {
          let mptpResponse =  this.twigParseGprsMessage(data.message);
          if(mptpResponse) this.readModeResponse.push({
            id: new Date().getTime(),
            command: mptpResponse.replace(/^!PSR/, "?CNF_01/01"),
          });
        }

        this.scrollToBottom();
      });
    },

    send() {
      if (this.command != "") this.sendCommand();
      else if (this.commandQueue.length > 0) this.queueCommand();
    },

    sendCommand(waitingTime = responseTimeout) {
      var vm = this;

      if (this.command != "" && this.alarm != null && this.isWaiting == false) {
        // currently only twig is implemented
        if (this.sendingMode == "SMS" && new RegExp("^TWIG").test(this.alarm.type))
          this.socket.emit("sms_command", {
            imei: this.alarm.imei_number,
            phone: this.alarm.phone,
            command: this.command,
            typeCommand: "simple_command",
            totalCommand: 1,
            currentNumber: 1,
            deviceType: this.alarm.type,
          });
        else
          this.socket.emit("command", {
            imei: this.alarm.imei_number,
            command: this.command,
            typeCommand: "simple_command",
            totalCommand: 1,
            currentNumber: 1,
            deviceType: this.alarm.type,
          });

        this.isWaiting = true;

        this.logs.push({
          timestamp: this.moment().format("HH:mm:ss"),
          type: "info",
          message: `Command sent to server, ${this.command}`,
        });
        this.scrollToBottom();

        let alarm_imei = this.alarm.imei_number;

        this.timeoutID = setTimeout(() => {
          if (vm.isWaiting && vm.alarm.imei_number == alarm_imei) {
            vm.isWaiting = false;
            vm.readMode = false;
            this.logs.push({
              timestamp: this.moment().format("HH:mm:ss"),
              type: "error",
              message: `Request timeout.`,
            });
          }
        }, waitingTime);
      }
    },

    queueCommand() {
      if (this.commandQueue.length > 0 && this.alarm != null && this.isWaiting == false) {
        this.logs.push({
          timestamp: this.moment().format("HH:mm:ss"),
          type: "info",
          message: `${this.commandQueue.length} commands queued`,
        });

        this.commandQueueTotal = this.commandQueue.length;
        this.commandQueueCurrent = 0;
        this.sendQueueCommand();
      }
    },

    sendQueueCommand() {
      var vm = this;

      if (this.commandQueue.length > 0) {
        let q = this.commandQueue.shift();
        this.commandQueueCurrent++;

        this.socket.emit("command", {
          imei: this.alarm.imei_number,
          command: q.command,
          typeCommand: "queue_command",
          totalCommand: this.commandQueueTotal,
          currentNumber: this.commandQueueCurrent,
          deviceType: this.alarm.type,
        });
        this.isWaiting = true;

        this.logs.push({
          timestamp: this.moment().format("HH:mm:ss"),
          type: "info",
          message: `Command ${this.commandQueueCurrent} of ${this.commandQueueTotal} sent to server, ${q.command}`,
        });
        this.scrollToBottom();

        let alarm_imei = this.alarm.imei_number;

        this.timeoutID = setTimeout(() => {
          if (vm.isWaiting && vm.alarm.imei_number == alarm_imei) {
            vm.isWaiting = false;
            this.logs.push({
              timestamp: this.moment().format("HH:mm:ss"),
              type: "error",
              message: `Request timeout.`,
            });
            // process next
            this.sendQueueCommand();
          }
        }, responseTimeout);
      } else {
        this.logs.push({
          timestamp: this.moment().format("HH:mm:ss"),
          type: "info",
          message: `All commands executed`,
        });
        this.scrollToBottom();

        // set all readed response to command queue
        if (this.readMode == true) {
          let readedCommandResponse = this.readModeResponse;
          this.reset();
          this.commandQueue = readedCommandResponse;
        } else this.reset();
      }
    },

    read() {
      this.reset();
      this.readMode = true;
      if (new RegExp("^TWIG").test(this.alarm.type)) {
        this.commandQueue = this.readCommandTwig;
        this.queueCommand();
      }
      else if (g2Alarms.indexOf(this.alarm.type) >= 0) {
        this.commandQueue = this.readCommandG2;
        this.queueCommand();
      } else {
        this.command = "#C14*00";
        this.sendCommand();
      }
    },

    getAlarmLastSeenTime(updatedAt) {
      return {
        value: this.moment(updatedAt).fromNow(),
        time: this.moment().diff(this.moment(updatedAt)),
      };
    },

    scrollToBottom() {
      this.$nextTick(function() {
        let el = this.$refs.configLog;
        el.scrollTop = el.scrollHeight;
      });
    },

    textFileRead() {
      this.commandQueue = [];
      this.command = "";

      if (this.$refs.commandTextFile.files.length > 0) {
        let file = this.$refs.commandTextFile.files[0];

        if (!file || file.type !== "text/plain") return;

        let reader = new FileReader();
        reader.readAsText(file, "UTF-8");

        reader.onload = (evt) => {
          let text = evt.target.result;
          let commandLines = text.split(/\r?\n/);
          if (commandLines[commandLines.length - 1] === "") commandLines.pop();

          for (let i = 0; i < commandLines.length; i++) {
            let c = commandLines[i].trim();

            if (new RegExp("^#C").test(c)) {
              this.commandQueue.push({
                id: i,
                command: c,
              });
            }
          }
        };

        reader.onerror = (evt) => {
          console.error(evt);
        };
      }
    },

    twigFileRead() {
      this.commandQueue = [];
      this.command = '';

      if(this.$refs.commandTextFileTwig.files.length > 0) {
        let file = this.$refs.commandTextFileTwig.files[0];

        if(file.type == 'text/plain') {
          let reader = new FileReader();
          reader.readAsText(file, "UTF-8");

          reader.onload =  evt => {
            let text = evt.target.result;
            let commandLines = text.split(/\r?\n/);
            if(commandLines[commandLines.length - 1] === '') commandLines.pop();

            for (let i = 0; i < commandLines.length; i++) {
              let c = commandLines[i].trim();
              
              if(/^\?CNF/.test(c)) {
                this.commandQueue.push({
                  id: i,
                  command: c
                });
              }          
            }
          }

          reader.onerror = evt => {
            console.error(evt);
          }
        }
        else {
          let headers = {
            'Content-Type': 'multipart/form-data',
            'X-Access-Token': this.$store.state.token,
          };

          var formData = new FormData();

          formData.append("twig", file);
          
          this.axios.post(`${process.env.VUE_APP_SERVER_URL}/alarm/config/twig/read-file`, formData, headers)
            .then((response) => {
              let commands = response.data.commands;

              for (let i = 0; i < commands.length; i++) {
                let c = commands[i].trim();
                
                this.commandQueue.push({
                  id: i,
                  command: c
                });     
              }
            })
            .catch((error) => {
              this.handleError(error);
            }) 
        }
      }
    },

    downloadConfig() {
      if (this.commandQueue.length > 0) {
        let configText = "";
        this.commandQueue.forEach((c) => {
          configText += c.command.replace(/(\r\n|\n|\r)/gm, "") + "\r\n";
        });

        let filename = `SecurCloud_Config.txt`;
        if (this.$refs.commandTextFile.files.length == 0) {
          let configV = "G1";
          if (g2Alarms.indexOf(this.alarm.type) >= 0) configV = "G2";

          filename = `${configV}_${this.alarm.type}_${this.customer.name}_${this.alarm.modelnumber}_${this.moment().format("YYYY-MM-DD")}.txt`;
        } else {
          filename = `SecurCloud_${this.moment().format("YYYY-MM-DD")}_${this.$refs.commandTextFile.files[0].name}`;
        }

        let element = document.createElement("a");
        element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(configText));
        element.setAttribute("download", filename);

        element.style.display = "none";
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      }
    },

    async downloadTwigConfig() {
      try {
        if(this.commandQueue.length > 0) {
          if(this.fileDownloadType == 'twig') {
            let data = {
              commands: this.commandQueue
            }
            let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/alarm/config/twig/download`, data);
            let xmlContent = response.data;

            let filename = `SecurCloud_${this.alarm.imei_number}_${this.moment().format('YYYY-MM-DD HH-mm')}.twig`;

            let element = document.createElement('a');
            element.setAttribute('href', 'data:text/xml;charset=utf-8,' + encodeURIComponent(xmlContent));
            element.setAttribute('download', filename);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
          }
          else {
            let configText = '';
            this.commandQueue.forEach(c => {
              configText += c.command.replace(/(\r\n|\n|\r)/gm, "") + '\r\n'; 
            });

            let filename = `SecurCloud_${this.alarm.imei_number}_${this.moment().format('YYYY-MM-DD')}.txt`;
            
            let element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(configText));
            element.setAttribute('download', filename);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);            
          }
        }
      } catch (error) {
        this.handleError(error);
      }
    },

    async getEraseAlarmConfigG1() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/alarms/config/g1/default`);
        this.eraseConfigCommandG1 = response.data.eraseConfigCommand;
      } catch (error) {
        this.handleError(error);
      }
    },

    async getEraseAlarmConfigG2() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/alarms/config/g2/default`);
        this.eraseConfigCommandG2 = response.data.eraseConfigCommand;
      } catch (error) {
        this.handleError(error);
      }
    },

    async getEraseAlarmConfigTwig() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/alarms/config/twig/default`);
        this.eraseConfigCommandTwig = response.data.eraseConfigCommand;
      } catch (error) {
        this.handleError(error);
      }
    },

    async getReadCommandG2() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/alarms/config/g2/read`);
        this.readCommandG2 = response.data.readCommand;
      } catch (error) {
        this.handleError(error);
      }
    },

    async getReadCommandTwig() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/alarms/config/twig/read`);
        this.readCommandTwig = response.data.readCommand;
      } catch (error) {
        this.handleError(error);
      }
    },

    eraseAlarmConfigPrompt() {
      this.$modal.show("dialog", {
        title: `<span class="text-red-500">Är du säker?</span><br>
                <span class="text-sm font-medium">Detta kommer att radera alla inställningar i larmet</span>`,
        buttons: [
          {
            title: '<div class="bg-accent-500 text-white text-sm font-sans py-2">Avbryt</div>',
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: '<div class="bg-red-500 text-white text-sm font-sans py-2">Default</div>',
            handler: () => {
              this.eraseAlarmConfig();
            },
          },
        ],
      });
    },

    eraseAlarmConfig() {
      if(new RegExp("^TWIG").test(this.alarm.type)) this.commandQueue = _.cloneDeep(this.eraseConfigCommandTwig);
      else if(g2Alarms.indexOf(this.alarm.type) >= 0) this.commandQueue = _.cloneDeep(this.eraseConfigCommandG2);
      else this.commandQueue = _.cloneDeep(this.eraseConfigCommandG1);
      this.queueCommand();
      this.$modal.hide("dialog");
    },

    reconnectPrompt() {
      this.$modal.show("dialog", {
        title: `<span class="font-normal text-lg">Återställa anslutningen med larm?</span>`,
        buttons: [
          {
            title: '<div class="bg-secondary-500 text-white text-sm font-sans py-2">Nej</div>',
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: '<div class="bg-red-500 text-white text-sm font-sans py-2">Ja</div>',
            handler: () => {
              this.reconnect();
            },
          },
        ],
      });
    },

    twigParseGprsMessage(gprsString) {
      if (new RegExp(/^BENR,/).test(gprsString.toString())) {
        let gprsArray = gprsString.toString().split(/,(?=(?:(?:[^#]*#){2})*[^#]*$)/g);

        let mptpString = gprsArray[4].slice(1, gprsArray[4].length - 1);

        return mptpString;
      }
      else return null;
    },

    reconnect() {
      this.socket.emit("reset_connection", { deviceType: this.alarm.type, imei: this.alarm.imei_number });
      this.$modal.hide("dialog");
    },

    checkConnection() {
      this.socket.emit("check_connection", { deviceType: this.alarm.type, imei: this.alarm.imei_number });
    },

    removeCommand(i) {
      this.commandQueue.splice(i, 1);
    },

    testConnection() {
      if (new RegExp("^TWIG").test(this.alarm.type)) this.command = "?SIR";
      else if (g2Alarms.indexOf(this.alarm.type) >= 0) this.command = "#C100*00";
      else this.command = "#C0*00";
      this.sendCommand(7000);
    },

    getTwigTrackingStopCommand() {
      if (this.alarm && this.alarm.imei_number.length >= 15) {
        let last8digitImei = this.alarm.imei_number.slice(this.alarm.imei_number.length - 8);
        this.command = `?RST_TRG${last8digitImei}`;
      } else this.command = `?TRG_7_0`;
    },

    async actTwigBatterySaverCommand() {
      let commands = [
        { id: 0, command: "?CNF_01/01_2020_900_2021_300_2033_0_2060_20_2441_0" }, 
        { id: 1, command: "?CNF_01/01_2860_240_2861_10_2863_0_2509_1170_2510_0" }, 
        { id: 2, command: "?RST_0" }, 
      ];

      this.commandQueue = commands;
    },

    stopQueue() {
      this.commandQueue = [];
      clearTimeout(this.timeoutID);
      this.timeoutID = null;
      this.isWaiting = false;
    },

    reset() {
      this.command = "";
      this.$refs.commandTextFile.value = null;
      this.$refs.commandTextFileTwig.value = null;
      this.commandQueue = [];
      this.readModeResponse = [];
      this.commandQueueTotal = 0;
      this.commandQueueCurrent = 0;
      this.readMode = false;
    },

    // check command which will break connection to securcloud
    checkDangerCommand(c) {
      let commandRegex = new RegExp(/^(#C21\*)|(#C22\*)|(#C23\*)|(#C26\*)|(#C160\*)|(#C315\*)/);
      return commandRegex.test(c);
    },

    checkDangerCommandTwig(c) {
      let commandRegex = new RegExp(/(2505)|(2517)|(2516)|(2515)|(2560)|(2522)|(2521)|(2520)|(2561)|(2527)|(2526)|(2525)|(2562)/);
      return commandRegex.test(c);
    },
  },

  created() {
    this.initSocketClient();
    this.getEraseAlarmConfigG1();
    this.getEraseAlarmConfigG2();
    this.getEraseAlarmConfigTwig();
    this.getReadCommandG2();
    this.getReadCommandTwig();
    this.setPageTitle(`Konfigurera larm`, "config_alarm");
  },

  beforeDestroy() {
    this.socket.disconnect();
  },
};
</script>
